
























import Vue from 'vue';
import {Component} from 'vue-property-decorator'
import ChannelsList from '@/components/ChannelsList.vue'
import DialogCreateChannel from '@/components/channels/DialogCreateChannel.vue'
import StandardMessage from "@/components/messages/StandardMessage.vue";

@Component({
  name: 'channels',
  components: {StandardMessage, ChannelsList, DialogCreateChannel}
})
export default class Channels extends Vue {
  public showCreateChannelDialog: boolean = false

  public onCreate(id) {
    this.$router.push({name: 'channel-detail', params: {channelId: id}})
  }
}
