
































import {Component, Vue, Watch} from 'vue-property-decorator';
import ChannelsListItem from '@/components/ChannelsListItem.vue'
import SearchView from './SearchView.vue';
import EmptyState from './EmptyState.vue';
import ToolTip from '@/components/custom/ToolTip.vue'
import {channelsStore} from '@/store/modules/channels';
import {applicationStore} from "@/store/modules/application";

@Component({
  name: 'channels-list',
  components: {SearchView, EmptyState, ToolTip, ChannelsListItem}
})
export default class ChannelsList extends Vue {
  srcItems?: any[] = []

  get businessChannels() {
    return channelsStore.businessChannels
  }

  get isEmpty() {
    return !this.businessChannels?.length
  }

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  @Watch('businessChannels')
  onItemsChanged(value, oldValue) {
    this.srcItems = Object.assign([], value);
  }

  public search(term) {
    if (!term) {
      this.srcItems = this.businessChannels;
      return;
    }
    const searchString = term.toLowerCase();
    this.srcItems = this.businessChannels!!.filter((value) =>
        value.name.toLowerCase().includes(searchString))
  }

  created() {
    this.srcItems = Object.assign([], this.businessChannels);
  }
}
