import { render, staticRenderFns } from "./DialogCreateNewGroupChat.vue?vue&type=template&id=db5e6d1a&scoped=true&"
import script from "./DialogCreateNewGroupChat.vue?vue&type=script&lang=ts&"
export * from "./DialogCreateNewGroupChat.vue?vue&type=script&lang=ts&"
import style0 from "./DialogCreateNewGroupChat.vue?vue&type=style&index=0&id=db5e6d1a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db5e6d1a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCol,VRow,VTab,VTabItem,VTabs,VTextField,VWindow,VWindowItem})
